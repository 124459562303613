import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, take, tap } from 'rxjs/operators';
import { RESET_IMPORT_OPTIN_CONFIG_FUNCTION } from 'src/app/app.constants';
import { BaseService } from 'src/app/core/abstractions/base-service';
import { FunctionOptions } from 'src/app/core/domain/function-options';
import { ToastService } from 'src/app/core/services/toast.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Functions } from '@angular/fire/functions';
import { FireLoggingService } from '../../../core/services/fire-logging.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService extends BaseService<any> {

    constructor(
        private spinnerService: NgxSpinnerService,
        private toastService: ToastService,
        protected firestore: AngularFirestore,
        protected http: HttpClient,
        protected functions: Functions,
        protected fireLogging: FireLoggingService
    ) {
        super(firestore, http, functions, fireLogging);
    }

    reset(companyId = '') {
        this.spinnerService.show();
        return this.callFunction(
            {companyId},
            RESET_IMPORT_OPTIN_CONFIG_FUNCTION,
            new FunctionOptions('callable')
        ).pipe(
            take(1),
            finalize(() => this.spinnerService.hide()),
            tap({
                next: (res) => {
                    this.toastService.updateSuccess();
                },
                error: (err) => {
                    this.toastService.disableError();
                    console.error(err.message);
                }
            })
        );
    }
}
